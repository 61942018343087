import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/ep8_art.png'

const Episode5 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">aint nobody home</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 8: Empty World</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="parched landscape" />
             <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8259038/height/50/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome back once more to Planet and Sky, a cosmic love story. In our last episode Sky dropped a heavy load on Planet, not in the form of the warm drenching rains he craved, but in the horrible news that she would be leaving him against her will as his gravitational force wasn’t up to the task of holding her any longer. He had a long time to have to face the music, and his dreary centuries were to drag on to countless millennia and he had to find something to do with his time.
            </p>

            <p>
              Stunned indeed.
            </p>

            <p>
              Planet was beyond devastated upon receiving the horrible news from Sky. He had long suspected that something was going wrong, but he was in deep denial. He had put all his faith in an eventual turn to a long summer that would restore the precious balance in the ecosystem he had helped her to create over so many long eons. It was simply unthinkable that he could lose his life’s companion and all the amazing children they had supported and helped to thrive and spread across their world.
            </p>

            <p>
              In addition to all the usual emotions associated with grief; sadness, denial, bargaining, and anger, he had an enormous serving of guilt to add to the mix. If only he could have accumulated more mass in his infancy, he would have been able to generate more heat and keep his internal dynamo going, thereby sustaining the protective magnetic field that shielded Sky and all of their children from destruction.
            </p>

            <p>
              But then again, would it have only delayed the inevitable? Maybe so, but it was little comfort knowing that they could have had a few more million years to play and raise their more and more capable lifeforms. Perhaps in time those children might have become intelligent enough to find a way to escape their cruel fate, but now the ones that were left were burrowing deeper and deeper in search of the last warmth they could find and any protection from the lethal radiation on the surface. He found himself projecting awareness into these inner havens to share in their misery as their situation grew more and more dire.
            </p>

            <p>
              It was on one of these occasions when he was analyzing an area of his crust upon which he had never focused much attention that he made a fascinating discovery. In all of the diverse assortment of creatures in his domain, there was one species that had developed in a particularly unusual direction. It was almost unnoticeable at first glance, as this species lived almost entirely beneath the surface and its individuals were microscopic in size. They had developed an interesting capability however, related to the clay sediments they inhabited. These clays had been naturally deposited in parallel thin sheets and had developed electrical charge as a byproduct of their process of creation. The microscopic species that inhabited the clays had found it possible to manipulate the charges in the layers of deposits. These patterned charges, in combination with their honeycomb-like growth patterns had resulted in a sort of long-duration memory device. The creatures had learned how to embed a history of their life progression in the charged clays.
            </p>

            <p>
              One day Planet was examining these structures while he was thinking nostalgically about Sky and some of their adventures. He thought it would be wonderful to tell her about the subterranean clay deposits while they flew above the planet like they often did, as on the occasion when they’d jumped from the ape creatures by the lake into the birds overhead. Soon enough he drifted off to other thoughts and other creatures in his remaining subterranean habitats. Some seemed to be engaged in long and fruitless arguments about their fate; one species insisting on finding ways to survive while another would be equally adamant that there was no hope to be found. Yet another seemed drawn to certain kinds of speckled rocks and would be clustered around them in fascination, utterly uninterested in the frantic arguments of the others.
            </p>

            <p>
              His awareness drifted back to the clay beds he’d been investigating a little while ago. He thought it would be wonderful to tell her about it while they flew above the planet like they often did, as on the occasion when they’d jumped from the ape creatures by the lake into... Hold on - what the hell just happened - was it deja vu? The thought he’d just had was an exact replica of one he’d been thinking the last time he came to this location.. Was it just something that reminded him of it or...  no, it was something stronger. The memory had impacted him strongly in this particular region - almost as if it had become locally persistent. He decided to try a test. He moved off to another section of the clay beds and thought about something different. This time he went all the way back to the first time he’d heard from Sky. She’d teased him about always looking at her while she flew around his surface. She had been irritating to him but also so much fun and he’d really been tormented by her teasing. He moved his focus to the first location and the memory of flying above the lake with her flooded back into him. Moving again to the alternate site, he vividly remembered her teasing him again like in his earliest memories. There could be no doubt. The clays were somehow storing and reactivating his memories!
            </p>

            <p>
              Planet was soon entranced with this game. He found that he could recall long stories in great detail while his mind focused on a particular section of the clays, then re-experience the memory as though living it for the first time. Moving his awareness to different areas he started to recreate the highlights of the eons of time he’d spent with her… creating mountains together, feeling her storms rain down upon him, carving great valleys, filling up mighty rivers and huge oceans. He catalogued all of her weather types, her endless kinds of clouds, her great range of temperatures, and the tremendous variety found in their children and all of their myriad characteristics. He would then wait for days, months, and even years and then return. In every case the memories would remain crystal clear. He had found a way to record the story of their love and it would stay in place for… well who could know how long?
            </p>

            <p>
              The years passed in the thousands on Planet’s lonely trip around the star he had shared with Sky for so long. He used the memory clays to relive his life with Sky over and over again, and occasionally interjected new details that would come to mind. He was beginning, though, to get more and more tired, and his thoughts became very slow and feeble. All activity was coming to an end. He had nothing more left and he knew that his life was finally complete. One day, after luxuriating in the clays, fondly re-living Sky and their glorious life, he chose to end his awareness - and his long life – thinking fondly of her. There was no longer any activity on the planet. It had come to an end. It was to stay in this lonely, empty, state for millions of years to come.
            </p>

            <p>
              One day, beyond another great gulf of time, a tiny pinprick of light in the dark sky grew gradually brighter. Was it a  star going into a nova state as it reached the end of its life? Perhaps a comet or an asteroid arriving on a collision course with this ancient world? No, it approached more slowly and it grew steadily in intensity. Suddenly it flared in brightness and a thin tail of flame became visible extending downward from the object, which now appeared as a cylinder hanging vertically against the stars. The polished metal of a spacecraft shone in the night sky as the vehicle made its gradual descent to the plain, kicking up great clouds of dust, and finally landing gently, upright against the distant hills, as the engines were extinguished.
            </p>

            <p>
              New life had found an old world.
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode5
